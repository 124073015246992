import React from "react";
import NavBar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import HomePage from "./components/Home";
// import SignUpPage from './components/SignUp';
import LoginPage from "./components/Login";
import TwoFactorAuth from "./components/TwoFactorAuth";
import Credentials from "./components/credentials/Credentials";
import Chat from "./components/chat/Chat";
import FileHandlingPage from "./components/minuteMaster/FileHandlingPage";
import Email from "./components/email/Email";
import Persona from "./components/Persona/Persona";
import Modules from "./components/trainAgent/Modules";
import ProtectedRoute from "./components/ProtectedRoute";
import Apps from "./pages/apps/Apps";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AdminPanel from "./components/userRole/AdminPanel";
import ManagerPanel from "./components/userRole/ManagerPanel";
import Notifications from "./components/Notifications";
import WebsiteSettings from "./components/websiteSettings/WebsiteSettings";
import DDQ_RFP from "./pages/DDQ_RFP/DDQ_RFP";
import Funddocs from "./pages/Funddocs/Funddocs";
import Campaign from "./components/Campaign/Campaign";
import Signature from "./components/Campaign/Signature";
import Sequences from "./components/Campaign/Sequences/Sequences";
import SequenceFormBox from "./components/Campaign/Sequences/SequenceFormBox/SequenceFormBox";
import EditSequenceFormBox from "./components/Campaign/Sequences/EditSequenceFormBox/EditSequenceFormBox";
import Mailboxes from "./components/Campaign/MailBox/Mailboxes";
import People from "./components/Campaign/People/People";
import Emails from "./components/Campaign/Emails/Emails";
import Upload from "./components/Campaign/Upload";
import { UserProvider } from "./components/userRole/UserContext";

const AppContent = () => {
  const location = useLocation();
  const shouldHideNavBar = () => {
    const paths = [
      "/login",
      "/forgot_password",
      "/auth/reset_password/",
      "/campaign",
      "/sequences",
      "/upload",
      "/signature",
      "/mailboxes",
      "/leads",
      "/analytics",
      "/two-factor-auth",
    ];
    return paths.some((path) => location.pathname.startsWith(path));
  };
  return (
    <div className="">
      {!shouldHideNavBar() && <NavBar />}
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/two-factor-auth">
          <TwoFactorAuth />
        </Route>
        <Route path="/forgot_password">
          <ForgotPassword />
        </Route>
        <Route path="/auth/reset_password/:token">
          <ResetPassword />
        </Route>
        {/* Pop-over links */}
        <ProtectedRoute
          path="/admin_panel"
          component={AdminPanel}
          roles={["Admin"]}
          // module="admin-panel"
          target="_blank"
        />
        <ProtectedRoute
          path="/manager_panel"
          component={ManagerPanel}
          roles={["Manager"]}
          // module="manager-panel"
          target="_blank"
        />
        <ProtectedRoute
          path="/notifications"
          component={Notifications}
          roles={["Admin", "Manager", "Employee"]}
          // module="notifications"
          target="_blank"
        />
        <ProtectedRoute
          path="/website_settings"
          component={WebsiteSettings}
          roles={["Admin"]}
          // module="website-settings"
          target="_blank"
        />
        <ProtectedRoute
          path="/apps"
          component={Apps}
          roles={["Admin", "Manager", "Employee"]}
          // module="apps"
          target="_blank"
        />
        <ProtectedRoute
          path="/credentials"
          component={Credentials}
          roles={["Admin", "Manager"]}
          // module="credentials"
          target="_blank"
        />
        {/* Pop-over links end*/}

        {/* NexusGenie Routes */}
        <ProtectedRoute
          path="/chat"
          component={Chat}
          roles={["Admin", "Manager", "Employee"]}
          module="NexusGenie"
          target="_blank"
        />
        <ProtectedRoute
          path="/train-agent"
          component={Modules}
          roles={["Admin", "Manager", "Employee"]}
          module="NexusGenie"
          target="_blank"
        />
        <ProtectedRoute
          path="/email"
          component={Email}
          roles={["Admin", "Manager", "Employee"]}
          module="NexusGenie"
          target="_blank"
        />
        <ProtectedRoute
          path="/persona"
          component={Persona}
          roles={["Admin", "Manager", "Employee"]}
          module="NexusGenie"
          target="_blank"
        />
        {/* NexusGenie Routes end */}

        <ProtectedRoute
          path="/minutes"
          component={FileHandlingPage}
          roles={["Admin", "Manager", "Employee"]}
          module="Minute Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/ddq_rfp"
          component={DDQ_RFP}
          roles={["Admin", "Manager", "Employee"]}
          module="DDQ Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/fund_docs"
          component={Funddocs}
          roles={["Admin", "Manager", "Employee"]}
          module="PitchComply"
          target="_blank"
        />
        {/* Market Master Routes */}
        <ProtectedRoute
          path="/sequences/:campaignData"
          component={Sequences}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/campaign/:campaignId/edit-sequence"
          component={EditSequenceFormBox}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/campaign/:campaignId/new-sequence"
          component={SequenceFormBox}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/campaign"
          component={Campaign}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />

        <ProtectedRoute
          path="/upload"
          component={Upload}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/signature"
          component={Signature}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/mailboxes"
          component={Mailboxes}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/leads"
          component={People}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />
        <ProtectedRoute
          path="/analytics"
          component={Emails}
          roles={["Admin", "Manager", "Employee"]}
          module="Market Master"
          target="_blank"
        />
        {/* Market Master Routes end */}

        <ProtectedRoute
          path="/"
          component={HomePage}
          roles={["Admin", "Manager", "Employee"]}
          module="Home"
          target="_blank"
        />
      </Switch>
    </div>
  );
};

const App = () => {
  return (
    <UserProvider>
      <Router>
        <AppContent />
      </Router>
    </UserProvider>
  );
};

export default App;
