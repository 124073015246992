import { useState, useEffect } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  Box,
  Flex,
  Text,
  VStack,
  Divider,
  useColorMode,
  HStack,
} from "@chakra-ui/react";
import CampaignInsights from "./CampaignInsights";
import CampaignInsightsWithChart from "./CampaignInsightsWithChart";
import SequenceInsights from "./SequenceInsights";
import { DateTime } from "luxon";

const SequenceItem = ({
  handleSequenceClick,
  currentCampaign,
  isFromBackend,
  campaignInsights,
  sequencesInsights,
}) => {
  // console.log("sequencesInsights", sequencesInsights);
  const { colorMode } = useColorMode();
  const campaignId = currentCampaign.campaignId;

  return (
    <Box
      as="section"
      m="2"
      h="86.5vh"
      p={4}
      pt={0}
      pb={0}
      pl={2}
      pr={2}
      bg={colorMode === "dark" ? "gray.700" : "white"}
      borderRadius="lg"
      border="1px"
      borderColor="blackAlpha.200"
    >
      <Flex direction="column" h="100%">
        {isFromBackend && (
          <Box
            flex="2"
            m={4}
            ml={2}
            mr={2}
            mb={0}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <CampaignInsights campaignInsights={campaignInsights} />
          </Box>
        )}
        <Box
          flex="8"
          m={4}
          ml={2}
          mr={2}
          borderRadius="md"
          border="1px"
          borderColor="blackAlpha.400"
          overflowY="scroll"
          scrollBehavior="hidden"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {currentCampaign &&
            currentCampaign.sequences.map((sequence, index) => {
              // Check if sequencesInsights is available and not null
              const sequenceInsight = sequencesInsights?.find(
                (insight) => insight.sequence_name === sequence.sequence_name
              );
              const formattedStartTime = DateTime.fromISO(
                sequence.sequence_start_time
              ).toFormat("MMM d, yyyy, h:mm a ZZZZ");

              const formattedEndTime = DateTime.fromISO(
                sequence.sequence_end_time
              ).toFormat("MMM d, yyyy, h:mm a ZZZZ");
              return (
                <Flex
                  key={index}
                  h={isFromBackend ? "30%" : "17%"}
                  p={4}
                  pt={2}
                  pb={2}
                  bg={colorMode === "dark" ? "gray.700" : "white"}
                  borderTopRadius="md"
                  borderBottom="1px"
                  borderColor="blackAlpha.400"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() =>
                    handleSequenceClick(sequence, index, sequence.sequence_id)
                  }
                  cursor={isFromBackend ? "pointer" : "default"}
                  _hover={{
                    bg: colorMode === "dark" ? "gray.600" : "blackAlpha.50",
                  }}
                >
                  <VStack
                    alignItems="left"
                    justifyContent="left"
                    spacing={1}
                    w={{ base: "10%", md: "7%", lg: "25%" }}
                  >
                    <Text
                      fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                      fontWeight="semibold"
                      mb={0}
                    >
                      {sequence.sequence_name}
                    </Text>
                    {isFromBackend && (
                      <Divider
                        w="90%"
                        m={0}
                        pr={4}
                        p={0}
                        orientation="horizontal"
                        borderColor="gray.300"
                      />
                    )}
                    {isFromBackend && (
                      <VStack
                        spacing={1}
                        alignItems="left"
                        justifyContent="left"
                        mr={4}
                      >
                        <HStack alignItems="center" spacing={1}>
                          <Text fontSize="xs" fontWeight="bold" mb={0}>
                            Start:
                          </Text>
                          <Text
                            fontSize="xs"
                            color="gray.500"
                            mb={0}
                            textAlign="center"
                          >
                            {" "}{formattedStartTime}
                          </Text>
                        </HStack>
                        <HStack alignItems="right" spacing={1}>
                          <Text
                            fontSize="xs"
                            fontWeight="bold"
                            mb={0}
                            textAlign="center"
                          >
                            End:
                          </Text>
                          <Text
                            fontSize="xs"
                            color="gray.500"
                            mb={0}
                            textAlign="center"
                          >
                            {formattedEndTime}
                          </Text>
                        </HStack>
                      </VStack>
                    )}
                  </VStack>
                  <Divider
                    orientation="vertical"
                    height="7vh"
                    h={isFromBackend ? "15vh" : "7vh"}
                    borderColor="gray.400"
                    sx={{ borderStyle: "dashed" }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    w={{ base: "90%", md: "93%", lg: "85%" }}
                  >
                  <Box   w={{ base: "90%", md: "93%", lg: "65%" }}>
                    <SequenceInsights
                      sequence={sequence}
                      agreed_for_meeting_count={
                        sequenceInsight?.agreed_for_meeting_count || 0
                      }
                      bounced_count={sequenceInsight?.bounced_count || 0}
                      ooo_count={sequenceInsight?.ooo_count || 0}
                      opened_count={sequenceInsight?.opened_count || 0}
                      replied_count={sequenceInsight?.replied_count || 0}
                      sent_count={sequenceInsight?.sent_count || 0}
                      // subscribed_count={sequenceInsight?.subscribed_count || 0}
                      total_active_email={
                        sequenceInsight?.total_active_email || 0
                      }
                      sequenceInsight={sequenceInsight}
                    />
                    </Box>
                    <Box w={{ base: "90%", md: "93%", lg: "35%" }}>
                    <CampaignInsightsWithChart
                      currentCampaign={currentCampaign}
                      sequence={sequence}
                      // Pass down the matched sequence insight data if available
                      status_red_count={sequenceInsight?.status_red_count || 0}
                      status_green_count={
                        sequenceInsight?.status_green_count || 0
                      }
                      status_grey_count={
                        sequenceInsight?.status_grey_count || 0
                      }
                      status_amber_count={
                        sequenceInsight?.status_amber_count || 0
                      }
                      status_count={sequenceInsight?.status_count || 0}
                      agreed_for_meeting_count={
                        sequenceInsight?.agreed_for_meeting_count || 0
                      }
                      bounced_count={sequenceInsight?.bounced_count || 0}
                      ooo_count={sequenceInsight?.ooo_count || 0}
                      opened_count={sequenceInsight?.opened_count || 0}
                      replied_count={sequenceInsight?.replied_count || 0}
                      sent_count={sequenceInsight?.sent_count || 0}
                      total_active_email={
                        sequenceInsight?.total_active_email || 0
                      }
                      valid_email_count={
                        sequenceInsight?.valid_email_count || 0
                      }
                      isFromBackend={isFromBackend}
                    />
                    </Box>
                  </Box>
                </Flex>
              );
            })}
        </Box>
      </Flex>
    </Box>
  );
};

export default SequenceItem;
