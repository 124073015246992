import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../helpers/axiosInstance";
import Sidebar from "../Sidebar";
import EmailHeader from "./EmailHeader";
import EmailThreadModal from "./EmailThreadModal";
import SummaryModal from "./SummaryModal";
import FilterBar from "./FilterBar";
import {
  Box,
  Flex,
  useColorMode,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";

function transformFilters(filterState) {
  const parseBoolean = (val) => {
    if (val === "true") return true;
    if (val === "false") return false;
    return val;
  };

  const { is_replied, is_bounced, is_opened, is_subscribed, feedback, is_ooo } =
    filterState;

  const result = {};

  if (is_replied !== "") result.is_replied = parseBoolean(is_replied);
  if (is_bounced !== "") result.is_bounced = parseBoolean(is_bounced);
  if (is_opened !== "") result.is_opened = parseBoolean(is_opened);
  if (is_subscribed !== "") result.is_subscribed = parseBoolean(is_subscribed);
  if (is_ooo !== "") result.is_ooo = parseBoolean(is_ooo);
  if (feedback !== "") result.feedback = feedback;

  return result;
}
const Emails = () => {
  const { colorMode } = useColorMode();
  const { search } = useLocation();

  const initialFilters = {
    is_replied: "",
    is_bounced: "",
    is_opened: "",
    is_subscribed: "",
    feedback: "",
    is_ooo: "",
  };

  // States
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [emails, setEmails] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [filterOptions, setFilterOptions] = useState({}); // To store filter options
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  // Track whether we should auto-fetch from URL
  const [shouldAutoFetch, setShouldAutoFetch] = useState(false);

  // Modals
  const {
    isOpen: isThreadModalOpen,
    onOpen: onThreadModalOpen,
    onClose: onThreadModalClose,
  } = useDisclosure();
  const {
    isOpen: isSummaryModalOpen,
    onOpen: onSummaryModalOpen,
    onClose: onSummaryModalClose,
  } = useDisclosure();
  const {
    isOpen: isActionModalOpen,
    onOpen: onActionModalOpen,
    onClose: onActionModalClose,
  } = useDisclosure();

  const [activeEmailId, setActiveEmailId] = useState(null);
  const [activeSummary, setActiveSummary] = useState("");
  const [activeAction, setActiveAction] = useState("");

  // Read URL params once
  useEffect(() => {
    const params = new URLSearchParams(search);
    const feedbackColor = params.get("feedback");
    const urlEmail = params.get("selectedEmail");
    const urlCampaign = params.get("selectedCampaign");

    // If the URL has certain values, set them in state
    if (urlEmail) setSelectedEmail(urlEmail);
    if (urlCampaign) setSelectedCampaign(urlCampaign);

    // If there's a feedback param, incorporate it into filters
    if (feedbackColor) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        feedback: feedbackColor,
      }));
    }

    // If we have both email + campaign from URL,
    // we'll want to auto-fetch once the states are set.
    if (urlEmail && urlCampaign) {
      setShouldAutoFetch(true);
    }
  }, [search]);

  // After states have been updated from URL params, trigger fetch once if needed
  useEffect(() => {
    if (shouldAutoFetch && selectedEmail && selectedCampaign) {
      handleApplyFilters();
      setShouldAutoFetch(false); // prevent future auto-fetches
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAutoFetch, selectedEmail, selectedCampaign, filters]);

  // Fetch all campaigns
  useEffect(() => {
    (async () => {
      try {
        const response = await axiosInstance.get("campaign/get-all-campaign");
        setCampaigns(response.data.data);
      } catch (error) {
        console.error("Failed to fetch campaigns:", error);
        setCampaigns([]);
      }
    })();
  }, []);

  const fetchThreads = (finalFilters) => {
    const payload = {
      campaign_id: selectedCampaign,
      connected_email: selectedEmail,
      page: currentPage,
      limit: pageSize,
      filters: finalFilters,
    };
    axiosInstance
      .post(`/threads/get-thread-emails`, payload)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data;

          // Set emails and filtered emails
          const emailsList = data.email_list.map((email) => ({
            id: email.conversation_id,
            sender: email.recipient_email,
            subject: email.subject,
            is_replied: String(email.is_replied),
            is_bounced: String(email.is_bounced),
            is_opened: String(email.is_opened),
            is_subscribed: String(email.is_subscribed),
            feedback: email.feedback || "No Feedback",
            is_ooo: String(email.is_ooo),
            // is_opened_count: email.is_opened_count || 0,
            reply_summry: email.reply_summry,
            action_points: email.action_points,
          }));

          setEmails(emailsList);
          setFilteredEmails(emailsList);
          setTotalPages(data?.total_pages || 0);

          // Set filter options dynamically
          const filterOptionsFromResponse = data.filter_options || {};
          setFilterOptions({
            is_bounced: filterOptionsFromResponse.unique_bounced || [],
            is_replied: filterOptionsFromResponse.unique_replied || [],
            is_opened: filterOptionsFromResponse.unique_opened || [],
            is_subscribed: filterOptionsFromResponse.unique_subscribed || [],
            feedback: filterOptionsFromResponse.unique_feedback || [],
            is_ooo: filterOptionsFromResponse.unique_ooo || [],
          });
        } else {
          setEmails([]);
          setFilteredEmails([]);
          setTotalPages(0);
        }
      })
      .catch((error) => {
        console.error("Error fetching threads:", error);
        setEmails([]);
        setFilteredEmails([]);
        setTotalPages(0);
      });
  };

  const hasAnyFilter = Object.values(filters).some((v) => v !== "");

  // If no filters => fetch automatically when campaign or email changes
  useEffect(() => {
    if (selectedCampaign && selectedEmail && !hasAnyFilter) {
      handleApplyFilters();
    }
    // eslint-disable-next-line
  }, [selectedCampaign, selectedEmail]);

  // Similarly for page changes
  useEffect(() => {
    if (selectedCampaign && selectedEmail && !hasAnyFilter) {
      handleApplyFilters();
    }
    // eslint-disable-next-line
  }, [currentPage, pageSize]);

  // Called on "Apply Filters" or automatic if no filters

  const handleApplyFilters = () => {
    if (!selectedEmail || !selectedCampaign) {
      console.warn("Please select both an email and a campaign.");
      return;
    }
    const transformed = transformFilters(filters);
    const hasValidFilter = Object.keys(transformed).length > 0;
    const finalFilters = hasValidFilter ? transformed : "";
    fetchThreads(finalFilters);
  };

  // Handlers
  const handleCampaignChange = (e) => {
    setSelectedCampaign(e.target.value);
    setCurrentPage(1);
    // If the user manually changes campaign, reset filters
    setFilters(initialFilters);
  };

  const handleEmailChange = (email) => {
    setSelectedEmail(email);
    setCurrentPage(1);
    // If the user manually changes email, reset filters
    setFilters(initialFilters);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const handleRemoveFilter = (filterKey) => {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: "",
    }));
  };

  const handlePageChange = (newPage) => setCurrentPage(newPage);
  const handlePreviousClick = () => {
    if (currentPage > 1) setCurrentPage((p) => p - 1);
  };
  const handleNextClick = () => {
    if (currentPage < totalPages) setCurrentPage((p) => p + 1);
  };
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  // Row actions
  const handleSubjectClick = (id) => {
    setActiveEmailId(id);
    onThreadModalOpen();
  };
  const handleViewClick = (e, summary) => {
    e.stopPropagation();
    setActiveSummary(summary);
    onSummaryModalOpen();
  };
  const handleActionClick = (e, action_points) => {
    e.stopPropagation();
    setActiveAction(action_points);
    onActionModalOpen();
  };

  // Feedback color logic
  const feedbackColors = {
    Red: "#FF9999", // Soft red
    Green: "#A4D3A4", // Soft green
    Grey: "#BEBEBE", // Soft gray
    Amber: "#FFE4A1", // Softer, less orange amber
  };

  const getRowColor = (feedback) => feedbackColors[feedback] || "white";

  // (Optional) if you use the "unique values" in FilterBar
  const getUniqueValues = (field) => [
    ...new Set(emails.map((email) => email[field])),
  ];

  return (
    <Flex height="100vh" overflow="hidden">
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.800" : "white"} w="85%">
        <EmailHeader
          handlePreviousClick={handlePreviousClick}
          handlePageChange={handlePageChange}
          handleNextClick={handleNextClick}
          totalPages={totalPages}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePageSizeChange={handlePageSizeChange}
          selectedEmail={selectedEmail}
          handleEmailChange={handleEmailChange}
          selectedCampaign={selectedCampaign}
          handleCampaignChange={handleCampaignChange}
          campaigns={campaigns}
        />

        <FilterBar
          filters={filters}
          onApplyFilters={handleApplyFilters}
          onRemoveFilter={handleRemoveFilter}
          handleFilterChange={handleFilterChange}
          selectedCampaign={selectedCampaign}
          filterOptions={filterOptions} // Pass dynamic filter options
        />

        <Box
          overflowY="auto"
          overflowX="auto"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          height="81vh"
          mt={2}
        >
          <Table variant="simple" size="sm" w="100%">
            <Thead>
              <Tr>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Email
                </Th>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Subject
                </Th>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Replied
                </Th>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Bounced
                </Th>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Opened
                </Th>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Subscribed
                </Th>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Feedback
                </Th>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Out of Office
                </Th>
                <Th
                  borderRight="1px solid"
                  borderColor="gray.200"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Summary
                </Th>
                <Th
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  Action Points
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredEmails.map((email, index) => (
                <Tr
                  key={index}
                  cursor="pointer"
                  backgroundColor={getRowColor(email.feedback)}
                  onClick={() => email.id && handleSubjectClick(email.id)}
                >
                  <Td borderRight="1px solid" borderColor="gray.200" w="100px">
                    {email.sender}
                  </Td>
                  <Td borderRight="1px solid" borderColor="gray.200">
                    {email.subject}
                  </Td>
                  <Td
                    borderRight="1px solid"
                    borderColor="gray.200"
                    textAlign="center"
                  >
                    {email.is_replied}
                  </Td>
                  <Td
                    borderRight="1px solid"
                    borderColor="gray.200"
                    textAlign="center"
                  >
                    {email.is_bounced}
                  </Td>
                  <Td
                    borderRight="1px solid"
                    borderColor="gray.200"
                    textAlign="center"
                  >
                    {email.is_opened}
                  </Td>
                  <Td
                    borderRight="1px solid"
                    borderColor="gray.200"
                    textAlign="center"
                  >
                    {email.is_subscribed}
                  </Td>
                  <Td
                    borderRight="1px solid"
                    borderColor="gray.200"
                    textAlign="center"
                  >
                    {email.feedback}
                  </Td>
                  <Td
                    borderRight="1px solid"
                    borderColor="gray.200"
                    textAlign="center"
                  >
                    {email.is_ooo}
                  </Td>
                  <Td
                    borderRight="1px solid"
                    borderColor="gray.200"
                    textAlign="center"
                  >
                    <IconButton
                      icon={<ViewIcon />}
                      size="sm"
                      aria-label="View Summary"
                      onClick={(e) => handleViewClick(e, email.reply_summry)}
                    />
                  </Td>
                  <Td textAlign="center">
                    <IconButton
                      icon={<ViewIcon />}
                      size="sm"
                      aria-label="View Action"
                      onClick={(e) => handleActionClick(e, email.action_points)}
                    />
                  </Td>
                </Tr>
              ))}

              {filteredEmails.length === 0 && (
                <Tr>
                  <Td colSpan={11} textAlign="center">
                    No emails to display
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </Box>
      {/* Thread Modal */}
      <EmailThreadModal
        isOpen={isThreadModalOpen}
        onClose={onThreadModalClose}
        campaignId={selectedCampaign}
        connectedEmail={selectedEmail}
        conversationId={activeEmailId}
      />

      {/* Summary Modals */}
      <SummaryModal
        isOpen={isSummaryModalOpen}
        onClose={onSummaryModalClose}
        summary={activeSummary}
        title="Summary"
      />
      <SummaryModal
        isOpen={isActionModalOpen}
        onClose={onActionModalClose}
        summary={activeAction}
        title="Action Point"
      />
    </Flex>
  );
};

export default Emails;
