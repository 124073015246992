import React from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  Tag,
  Wrap,
  TagLabel,
  TagCloseButton,
  Select,
} from "@chakra-ui/react";

const filterTitles = {
  is_replied: "Replied",
  is_bounced: "Bounced",
  is_opened: "Opened",
  is_subscribed: "Subscribed",
  feedback: "Feedback",
  is_ooo: "OOO",
};

/**
 * @param {Object}   filters            - Current filters (e.g. { is_replied: 'true', is_bounced: '', ... })
 * @param {Function} onApplyFilters     - Called when "Apply Filters" is clicked
 * @param {Function} onRemoveFilter     - Removes a single filter (by key)
 * @param {Function} handleFilterChange - Called when user selects a new filter dropdown value
 * @param {string}   selectedCampaign   - Selected campaign ID or name
 * @param {Object}   filterOptions      - Available options for each filter (e.g. { is_replied: ["true", "false"], ... })
 */
const FilterBar = ({
  filters,
  onApplyFilters,
  onRemoveFilter,
  handleFilterChange,
  selectedCampaign,
  filterOptions,
}) => {
  // console.log("filterOptions", filterOptions);
  // Make a list of filters that have non-empty values
  const selectedFilters = Object.entries(filters).filter(
    ([, value]) => value !== ""
  );

  return (
    <Box
      p={3}
      borderBottom="1px"
      borderColor="blackAlpha.200"
      background="gray.50"
    >
      {/* Selected Filters Tags */}
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Flex alignItems="center">
          <Text fontWeight="semibold" mr={3} mb={0}>
            Filters:
          </Text>
          <Wrap
            sx={{
              "& .chakra-wrap__list": {
                marginBottom: "0 !important", // remove default bottom margin
              },
            }}
          >
            {selectedFilters.length > 0 ? (
              selectedFilters.map(([key, value]) => {
                const label = filterTitles[key] || key;
                return (
                  <Tag
                    key={key}
                    colorScheme="blue"
                    size="sm"
                    p={1}
                    px={2}
                    borderRadius="full"
                  >
                    <TagLabel>
                      {label}: {value}
                    </TagLabel>
                    <TagCloseButton onClick={() => onRemoveFilter(key)} />
                  </Tag>
                );
              })
            ) : (
              <Text fontSize="sm" fontStyle="italic" mb={0}>
                No filters selected
              </Text>
            )}
          </Wrap>
        </Flex>

        <Button colorScheme="blue" size="sm" onClick={onApplyFilters}>
          Apply Filters
        </Button>
      </Flex>

      {/* Render Filter Dropdowns */}
      {selectedCampaign && (
        <Wrap
          sx={{
            "& .chakra-wrap__list": {
              marginBottom: "0 !important", // remove default bottom margin
            },
          }}
        >
          {Object.entries(filterOptions).map(([filterKey, options]) => (
            <Box key={filterKey}>
              <Select
                size="sm"
                borderRadius="full"
                placeholder={filterTitles[filterKey] || filterKey}
                value={filters[filterKey]}
                onChange={(e) => handleFilterChange(filterKey, e.target.value)}
              >
                {options.map((value) => (
                  <option key={value} value={value}>
                    {String(value)}{" "}
                    {/* Ensure proper rendering of boolean values */}
                  </option>
                ))}
              </Select>
            </Box>
          ))}
        </Wrap>
      )}
    </Box>
  );
};

export default FilterBar;
