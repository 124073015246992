import React from "react";
import {
  Box,
  Text,
  Progress,
  Grid,
  IconButton,
  Tooltip,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";

// Regular insight component for all insights except "Unsubscribed"
const InsightItem = ({ insight }) => {
  return (
    <Box
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.200"
      maxW="auto"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="110px"
    >
      <Box
        display="flex"
        alignItems="center"
        mb={2}
        ml={2}
        pt={2}
        pl={0}
        pr={0}
      >
        <Box
          minW="10px"
          minH="10px"
          maxH="10px"
          maxW="10px"
          borderRadius="50%"
          bg={insight.color}
          mr={2}
        />
        <Text
          fontWeight="semibold"
          color="blackAlpha.700"
          fontSize="sm"
          mb={0}
          noOfLines={1}
          lineHeight="1.2"
          isTruncated
        >
          {insight.label}
        </Text>
        <Spacer />
        <Tooltip label={`${insight.info}`} placement="right">
          <IconButton
            aria-label="Info"
            icon={<AiOutlineInfoCircle />}
            size="xs"
            variant="ghost"
            _hover="none"
          />
        </Tooltip>
      </Box>
      <Text fontSize="lg" fontWeight="bold" ml={2} mb={0} pl={1} pr={1}>
        {Math.round(insight.percentage * 100) / 100}%
      </Text>
      <Text
        fontSize="xs"
        fontWeight="semibold"
        color="blackAlpha.700"
        ml={2}
        mb={0}
        pl={1}
        pr={1}
      >
        {insight.count} {insight.total ? ` / ${insight.total}` : ""}
      </Text>

      <Progress
        value={insight.percentage}
        size="xs"
        colorScheme={insight.color.replace(".400", "")}
        borderRadius="md"
        mt="auto"
      />
    </Box>
  );
};

// Unique component for "Unsubscribed" insight
const UnsubscribedInsight = ({ percentage, color, info }) => (
  <Box
    bg="white"
    borderRadius="md"
    boxShadow="sm"
    border="1px solid"
    borderColor="gray.200"
    maxW="auto"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    minHeight="110px"
    color={color}
  >
    <Box display="flex" alignItems="center" mb={2} ml={2} pt={2} pl={0} pr={0}>
      <Box
        minW="10px"
        minH="10px"
        maxH="10px"
        maxW="10px"
        borderRadius="50%"
        bg={color}
        mr={2}
      />
      <Text
        fontWeight="semibold"
        color="blackAlpha.700"
        fontSize="sm"
        mb={0}
        noOfLines={1}
        lineHeight="1.2"
        isTruncated
      >
        Unsubscribed
      </Text>
      <Spacer />
      <Tooltip label={info} placement="right">
        <IconButton
          aria-label="Info"
          icon={<AiOutlineInfoCircle />}
          size="xs"
          variant="ghost"
          _hover="none"
        />
      </Tooltip>
    </Box>
    <Text fontSize="3xl" fontWeight="bold" textAlign="center" color="black">
      {Math.round(percentage * 100) / 100}%
    </Text>
    <Progress
      value={percentage}
      size="xs"
      colorScheme={color.replace(".400", "")}
      borderRadius="md"
      mt="auto"
    />
  </Box>
);

const CampaignInsights = ({ campaignInsights }) => {
  // console.log("campaignInsights",campaignInsights)
  if (!campaignInsights) {
    return <Text></Text>;
  }

  const insightData = [
    {
      label: "Sent",
      percentage: campaignInsights.total_sent_percentage || 0,
      count: campaignInsights.total_sent_count || 0,
      total: campaignInsights.total_scheduled_emails || 0,
      color: "gray.400",
      info: "Emails successfully sent in this campaign.",
    },
    {
      label: "Opened",
      percentage: campaignInsights.total_opened_percentage || 0,
      count: campaignInsights.total_is_opened_emails || 0,
      total: campaignInsights.total_scheduled_emails || 0,
      color: "blue.400",
      info: "Percentage of sent emails opened by recipients.",
    },
    {
      label: "Replied",
      percentage: campaignInsights.total_replied_percentage || 0,
      count: campaignInsights.total_is_replied_emails || 0,
      total: campaignInsights.total_scheduled_emails || 0,
      color: "purple.400",
      info: "Percentage of opened emails that received replies.",
    },
    {
      label: "Bounced",
      percentage: campaignInsights.total_bounced_percentage || 0,
      count: campaignInsights.total_bounced_count || 0,
      total: campaignInsights.total_bounced_email_count || 0,
      color: "red.400",
      info: "Emails that failed to deliver (bounced).",
    },
    {
      label: "Valid Email",
      percentage: campaignInsights.total_valid_email_percentage || 0,
      count: campaignInsights.total_valid_count || 0,
      total: campaignInsights.total_valid_email_count || 0,
      color: "green.400",
      info: "Emails that reached the recipients' inbox.",
    },
    {
      label: "OOO Count",
      percentage: campaignInsights.total_ooo_percentage || 0,
      count: campaignInsights.total_ooo_count || 0,
      total: campaignInsights.out_of_ooo_count || 0,
      color: "orange.400",
      info: "Emails that received out-of-office replies.",
    },
    {
      label: "Booked Meeting",
      percentage: campaignInsights.total_agreed_for_meeting_percentage || 0,
      count: campaignInsights.total_agreed_for_meeting_count || 0,
      total: campaignInsights.out_of_agreed_for_meeting_count || 0,
      color: "teal.400",
      info: "Recipients who scheduled a meeting from these emails.",
    },
  ];

  return (
    <Box>
      <Text fontWeight="bold" fontSize="md" mb={4}>
        Total Scheduled Emails: {campaignInsights.total_scheduled_emails || 0}
      </Text>
      <Grid templateColumns="repeat(8, 1fr)" gap={2}>
        {insightData.map((insight, index) => (
          <InsightItem key={index} insight={insight} />
        ))}
        <UnsubscribedInsight
          percentage={campaignInsights.un_subscribed_percentage || 0}
          color="pink.400"
          info="Percentage of recipients who unsubscribed from this campaign."
        />
      </Grid>
    </Box>
  );
};

export default CampaignInsights;
