import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, useColorMode } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  Tooltip as ChartTooltip,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register the chart elements
ChartJS.register(BarElement, ChartTooltip, CategoryScale, LinearScale);

// Custom plugin to add colored boxes next to Y-axis labels
const customColorLabelsPlugin = {
  id: "customColorLabels",
  afterDraw: (chart) => {
    const {
      ctx,
      scales: { y },
    } = chart;

    // Colors corresponding to labels [Red, Green, Amber]
    const colors = ["red", "green", "#FFBF00"];
    const labels = chart.data.labels;

    labels.forEach((label, index) => {
      const yPosition = y.getPixelForValue(index); // Get Y position for each label
      ctx.save();
      ctx.fillStyle = colors[index]; // Set the fill color
      ctx.fillRect(y.left - 20, yPosition - 5, 10, 10); // Draw the colored square
      ctx.restore();
    });
  },
};

// Register the plugin globally
ChartJS.register(customColorLabelsPlugin);

const CampaignInsightsWithChart = ({
  currentCampaign,
  status_red_count = 0,
  status_green_count = 0,
  status_amber_count = 0,
  // Not using grey
  isFromBackend,
}) => {
  const { colorMode } = useColorMode();
  const history = useHistory();

  const selectedEmail = currentCampaign?.connected_email;
  const selectedCampaign = currentCampaign?.campaign_id;

  // 1. Calculate total
  const totalCount =
    status_red_count + status_green_count + status_amber_count;

  // 2. Compute percentages (avoid division by zero)
  const redPercentage =
    totalCount === 0 ? 0 : (status_red_count / totalCount) * 100;
  const greenPercentage =
    totalCount === 0 ? 0 : (status_green_count / totalCount) * 100;
  const amberPercentage =
    totalCount === 0 ? 0 : (status_amber_count / totalCount) * 100;

  // Store both the original counts and the derived percentages
  const counts = [status_red_count, status_green_count, status_amber_count];
  const percentages = [
    redPercentage.toFixed(2),
    greenPercentage.toFixed(2),
    amberPercentage.toFixed(2),
  ];

  // 3. Prepare the bar data
  const barData = {
    labels: ["Red", "Green", "Amber"],
    datasets: [
      {
        label: "Percentage",
        data: percentages, // Use percentages for the bar lengths
        // Store the raw counts in a custom property
        countData: counts,
        backgroundColor: ["red", "green", "#FFBF00"],
        hoverBackgroundColor: ["red", "green", "#FFBF00"],
        barThickness: 4,
        categoryPercentage: 0.4,
        barPercentage: 0.5,
      },
    ],
  };

  // 4. Chart options
  const barOptions = {
    responsive: true,
    indexAxis: "y", // Horizontal bars
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 40, // spacing for color squares
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            // Retrieve the index of the hovered bar
            const dataIndex = context.dataIndex;
            const countValue = dataset.countData[dataIndex];
            const percentageValue = dataset.data[dataIndex];

            // You can choose to display just the count or both
            // Example: "Count: 20 (20%)"
            return `Count: ${countValue} (${percentageValue}%)`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          // Show % sign on the x-axis
          callback: function (value) {
            return `${value}%`;
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          padding: 10,
          font: {
            size: 11,
          },
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const clickedElementIndex = elements[0].index;
        const label = barData.labels[clickedElementIndex];
        handleColorClick(label);
      }
    },
  };

  // 5. Handle bar clicks
  const handleColorClick = (color) => {
    history.push(
      `/analytics?feedback=${color}&selectedEmail=${encodeURIComponent(
        selectedEmail
      )}&selectedCampaign=${encodeURIComponent(selectedCampaign)}`
    );
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      {isFromBackend && (
        <Flex justifyContent="center" alignItems="center" mt={2} mb={2}>
          <Box
            width={{ base: "100px", md: "200px", lg: "250px" }}
            height={{ base: "50px", md: "110px", lg: "110px" }}
            display="flex"
          >
            <Bar data={barData} options={barOptions} />
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default CampaignInsightsWithChart;
