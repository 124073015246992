import axiosInstance from '../../helpers/axiosInstance';


// export const fetchModelList = async () => {
//   try {
//     const response = await axiosInstance.get(`chat/models`);
//     return response.data; // Assuming the server responds with a list of models
//   } catch (error) {
//     console.error("Error fetching model list:", error);
//     throw error; // Rethrow the error for handling it in the calling component
//   }
// };


export const fetchModelList = async () => {
    // Simulating an asynchronous operation with setTimeout
    return new Promise(resolve => {
      setTimeout(() => {
        resolve([
          { value: 'gpt-4-turbo-preview', label: 'gpt-4-turbo-preview' },
          { value: 'gpt-4', label: 'gpt-4' },
          { value: 'o1-preview', label: 'o1-preview' },
          // { value: 'o1-mini', label: 'o1-min' },
          // Add more dummy models here
        ]);
      }, 500); // Simulating a delay of 1 second
    });
  };
  