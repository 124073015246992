import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";

const SummaryModal = ({ isOpen, onClose, summary, title }) => {
  // Fallback message for missing summary
  const fallbackMessage = `No ${title} available.`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxH="78vh">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody h="100%" overflowY="auto">
          {/* Display the summary or fallback message */}
          <Text>{summary || fallbackMessage}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SummaryModal;
