import React from "react";
import {
  Box,
  Flex,
  Heading,
  useColorMode,
  Button,
  ButtonGroup,
  Select,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import EmailDropdown from "../../common/EmailDropdown"; // Adjust import path if necessary

const EmailHeader = ({
  handlePreviousClick,
  handlePageChange,
  handleNextClick,
  totalPages,
  currentPage,
  pageSize,
  setPageSize,
  handlePageSizeChange,
  selectedEmail,
  handleEmailChange,
  selectedCampaign,
  handleCampaignChange,
  campaigns,
}) => {
  const { colorMode } = useColorMode(); // useColorMode hook to access colorMode

  return (
    <>
      <Box
        p={5}
        pl={6}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        h="10.7vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
          Inbox
        </Heading>
      </Box>
      <Box
        p={1}
        pl={5}
        pr={5}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        h="7vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Pagination Controls */}
        <Flex align="center" justifyContent="center" mt={0} h="4vh" gap="3">
          {/* NEW: Limit (Items per Page) Dropdown */}
          <Select
            size="xs"
            width="80px"
            value={pageSize}
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
          >
            {[5, 10, 20, 50, 100].map((limit) => (
              <option key={limit} value={limit}>
                {limit}
              </option>
            ))}
          </Select>

          <ButtonGroup isAttached variant="outline">
            <Button
              mt="1px"
              size="xs"
              onClick={handlePreviousClick}
              disabled={currentPage <= 1}
            >
              <ChevronLeftIcon />
            </Button>
            <Select
              size="xs"
              value={currentPage}
              onChange={(e) => handlePageChange(Number(e.target.value))}
            >
              {Array.from({ length: totalPages }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </Select>
            <Button
              mt="1px"
              size="xs"
              onClick={handleNextClick}
              disabled={currentPage >= totalPages}
            >
              <ChevronRightIcon />
            </Button>
          </ButtonGroup>
        </Flex>

        {/* Campaign and Email Dropdowns */}
        <Flex w="40%" justifyContent="space-between">
          <Box w="48%">
            <EmailDropdown
              selectedEmail={selectedEmail}
              setSelectedEmail={handleEmailChange}
            />
          </Box>
          <Box w="48%">
            <Select
              placeholder="Select Campaign"
              size="sm"
              borderRadius="md"
              value={selectedCampaign}
              onChange={handleCampaignChange}
              isDisabled={!selectedEmail}
            >
              {campaigns.map((campaign) => (
                <option key={campaign.campaign_id} value={campaign.campaign_id}>
                  {campaign.campaign_name}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default EmailHeader;
